import { Modal } from "@mui/material";
import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { url } from "../Api";
import { AdminHouseDetail } from "../Components/AdminHouseAndLandDetails";
import CreateHouse from "../Components/CreateHouse";
import EditHouse from "../Components/EditHouse";
import { PageContext } from "../Context/PageContextProvider";
import { useTable } from "react-table";

const AdminListHouse = () => {
  const [addModal, setAddModal] = useState(false);
  const [descModal, setDescModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const { userInfo } = useContext(PageContext);
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(false);
  const [getId, setGetId] = useState("");
  const [search, setSearch] = useState("");
  const [filteredHouse, setFilteredHouse] = useState([]);

  const getProperties = useCallback(() => {
    setLoading(true);
    const fn1 = async () => {
      let res = await axios.get(`${url}/properties`, {
        headers: {
          Accept: "application/json",
          Authorization: `bearer ${userInfo?.token}`,
        },
      });
      let data = res.data.properties;
      let house = data.filter((property) => {
        return property.type === "House";
      });
      setProperties(house);
      setFilteredHouse(house);
      setLoading(false);
    };

    fn1();
  }, [userInfo?.token]);

  useEffect(() => {
    getProperties();
  }, [getProperties]);

  const deleteProperty = (id) => {
    const fn1 = async () => {
      alert("Are you sure you want to delete property");
      let res = await axios.delete(`${url}/properties/${id}`, {
        headers: {
          Authorization: `bearer ${userInfo?.token}`,
        },
      });
      const newProperty = properties.filter((item) => item.id !== id);
      setProperties(newProperty);
      if (res) {
        window.location.reload();
      }
    };
    fn1();
  };

  const openDetails = (id) => {
    setGetId(id);
    setDescModal(true);
  };

  const openEdit = (id) => {
    setEditModal(true);
    setGetId(id);
  };

  useEffect(() => {
    const result = properties.filter((item) => {
      return item.name.toLowerCase().match(search.toLocaleLowerCase());
    });
    setFilteredHouse(result);
  }, [search]);

  const columns = React.useMemo(
    () => [
      { Header: "Name", accessor: "name" },
      { Header: "Location", accessor: "location" },
      {
        Header: "Price",
        Cell: ({ row }) => (
          <span>
            {row.original.price.toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            })}
          </span>
        ),
      },
      {
        Header: "Initial deposit",
        Cell: ({ row }) => (
          <span>
            {row.original.intialDeposit.toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            })}
          </span>
        ),
      },
      {
        Header: "BedRoom",
        Cell: ({ row }) => <span>{row.original.details[0]}</span>,
      },
      {
        Header: "BathRoom",
        Cell: ({ row }) => <span>{row.original.details[1]}</span>,
      },
      {
        Header: "Action",
        Cell: ({ row }) => (
          <div className="flex items-center gap-3 justify-start">
            <i
              onClick={() => openDetails(row.original._id)}
              className="ri-eye-line cursor-pointer hover:text-primary text-lg"
            ></i>
            <i
              onClick={() => openEdit(row.original._id)}
              className="ri-pencil-fill cursor-pointer hover:text-primary text-lg"
            ></i>
            <i
              onClick={() => deleteProperty(row.original._id)}
              className="ri-delete-bin-6-line cursor-pointer hover:text-primary text-lg"
            ></i>
          </div>
        ),
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: filteredHouse,
    });

  return (
    <div>
      <div className="md:-mt-14 flex justify-start md:justify-end gap-3">
      <div>
          <input
            placeholder="Search property.."
            className="border border-slate-500 py-2 pl-2 pr-5 font-medium rounded text-sm focus:outline-primary"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <button onClick={() => setAddModal(true)} className="button">
          Add +
        </button>
      </div>
      <CreateHouse open={addModal} handleClose={() => setAddModal(false)} />
      <AdminHouseDetail
        id={getId}
        open={descModal}
        handleClose={() => setDescModal(false)}
      />
      <EditHouse
        id={getId}
        open={editModal}
        handleClose={() => setEditModal(false)}
      />

      {!loading && properties.length === 0 && (
        <div>
          <h5 className="pt-4 font-medium text-lg"> No Property yet</h5>
        </div>
      )}

      {loading && (
        <div>
          <h5 className="pt-4 font-medium text-lg">Loading....</h5>
        </div>
      )}

      {properties.length > 0 && (
        <table {...getTableProps()} className="w-full table-auto text-left mt-4">
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()} className="">
                {headerGroup.headers.map((column, i) => (
                  <th
                    key={i}
                    {...column.getHeaderProps()}
                    className="text-xs py-2 text-accent"
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()} className="text-[#2E2E2E]">
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr key={i} {...row.getRowProps()}>
                  {row.cells.map((cell, i) => {
                    return (
                      <td
                        key={i}
                        {...cell.getCellProps()}
                        className="py-2 text-sm px-2"
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default AdminListHouse;
