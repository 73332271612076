import React, { useContext, useRef, useState } from "react";
import { Modal, Tooltip } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import PageToper from "../Components/PageToper";
import { AccountContext } from "../Context/AccountContextProvider";
import DashboardLayout from "../Layout/DashboardLayout";
import { toast } from "react-toastify";
import axios from "axios";
import { url } from "../Api";
import { useNavigate } from "react-router-dom";
import { ViewerForm } from "../Components/Helper/ViewerForm";

const AdminAccount = () => {
  const [resetPModal, setResetPModal] = useState(false);
  const [editProfileModal, setEditProfileModal] = useState(false);
  const { userAccount, userInfo } = useContext(AccountContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const validate = Yup.string().required("Field is required!");
  const textAreaRef = useRef(null);

  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand("copy");
    e.target.focus();
    notify();
  }
  const notify = () => toast.success("Copied!");
  const submitProfile = (values) => {
    setLoading(true);
    axios
      .patch(
        `${url}/user/profile/update/personal
`,
        values,
        {
          headers: {
            Accept: "application/json",
            Authorization: `bearer ${userInfo?.token}`,
          },
        }
      )
      .then((response) => {
        setLoading(false);
        toast.success(response.data.message);
        window.location.reload();
      })

      .catch((err) => {
        toast.error(err.message);
        setLoading(false);
      });
  };

  const onSubmitReset = (values, onSubmitProps) => {
    // setOpenBackDrop(true);
    axios({
      url: `${url}/admin/change/password`,
      method: "post",
      headers: {
        Accept: "application/json",
        Authorization: `bearer ${userInfo?.token}`,
      },
      data: {
        password: values.password,
        newPassword: values.newPassword,
      },
    })
      .then((result) => {
        toast.success(result.message);
        localStorage.clear("user_info");
        navigate("/login");
      })
      .catch((err) => {
        toast.error(err.message);
      });

    onSubmitProps.resetForm();
  };

  return (
    <DashboardLayout>
      <div className="Container">
        <PageToper
          title="Account (Admin)"
          desc={userAccount.fullname}
          adminAccount
        />
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div>
            <div>
              <div className="flex justify-end gap-4 mb-3">
                <button
                  className="button"
                  onClick={() => setEditProfileModal(true)}
                >
                  Edit Profile
                </button>
                <button className="button" onClick={() => setResetPModal(true)}>
                  Reset password
                </button>
              </div>
              <ViewerForm
                label="Full Name"
                placeholder={userAccount.fullname}
                type="input"
              />
              <ViewerForm
                label="Email"
                placeholder={userAccount.email}
                type="input"
              />
              <ViewerForm
                label="Phone Number"
                placeholder={userAccount.phone}
                type="input"
              />

              <div className="mt-6">
                <h5 className="pb-1 text-accent font-medium">Referral Link</h5>
                <div className="flex items-center gap-4 bg-gray-100 px-2">
                  <input
                    type="text"
                    ref={textAreaRef}
                    value={`https://bricks-image.netlify.app/register?role=admin`}
                    className="focus:outline-none w-full bg-gray-100 py-2 text-sm rounded-md"
                  />
                  <Tooltip title="Copy Referral Code">
                    <i
                      className="ri-file-copy-line hover:text-primary text-lg cursor-pointer"
                      onClick={copyToClipboard}
                    ></i>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Reset password modal */}
        <Modal open={resetPModal} onClose={() => setResetPModal(false)}>
          <div className="CModal" style={{ maxWidth: 400 }}>
            <div className="flex justify-between items-center mb-7">
              <h5 className="font-semibold text-accent text-lg">
                Reset Password
              </h5>
              <i
                className="fas fa-times cursor-pointer text-xl"
                onClick={() => setResetPModal(false)}
              ></i>
            </div>
            <Formik
              initialValues={{
                password: "",
                newPassword: "",
                confirmPassword: "",
              }}
              validationSchema={Yup.object({
                password: validate,
                newPassword: validate.min(
                  8,
                  "Password must not be lass than 8 characters"
                ),
                confirmPassword: Yup.string()
                  .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
                  .required("Field is required!"),
              })}
              onSubmit={onSubmitReset}
            >
              <Form>
                <div className="form-control">
                  <label>Old Password</label>
                  <Field
                    type="password"
                    name="password"
                    placeholder="Enter old password"
                  />
                  <ErrorMessage
                    name="password"
                    component="span"
                    className="errorMsg"
                  />
                </div>
                <div className="form-control">
                  <label>New Password</label>
                  <Field
                    type="password"
                    name="newPassword"
                    placeholder="Enter new password"
                  />
                  <ErrorMessage
                    name="newPassword"
                    component="span"
                    className="errorMsg"
                  />
                </div>
                <div className="form-control">
                  <label>Confirm Password</label>
                  <Field
                    type="password"
                    name="confirmPassword"
                    placeholder="Confirm password"
                  />
                  <ErrorMessage
                    name="confirmPassword"
                    component="span"
                    className="errorMsg"
                  />
                </div>
                <button className="button" type="submit">
                  Submit
                </button>
              </Form>
            </Formik>
          </div>
        </Modal>

        {/* Edit profile modal */}
        <Modal
          open={editProfileModal}
          onClose={() => setEditProfileModal(false)}
        >
          <div className="CModal" style={{ maxWidth: 400 }}>
            <div className="flex justify-between items-center mb-7">
              <h5 className="font-semibold text-accent text-lg">
                Edit Profile
              </h5>
              <i
                className="fas fa-times cursor-pointer text-xl"
                onClick={() => setEditProfileModal(false)}
              ></i>
            </div>
            <Formik
              initialValues={{
                fullname: userAccount.fullname,
                email: userAccount.email,
                phone: userAccount.phone,
              }}
              validationSchema={Yup.object({
                fullname: validate,
                email: validate.email("Invalid email format"),
                phone: Yup.string()
                  .min(11, "Incomplete phone number")
                  .max(11, "Wrong number format")
                  .matches(/^[0-9]/, "only number allowed")
                  .trim(),
              })}
              onSubmit={submitProfile}
            >
              <Form>
                <div className="form-control">
                  <label>Full name</label>
                  <Field type="text" name="fullname" />
                  <ErrorMessage
                    component="span"
                    className="errorMsg"
                    name="fullname"
                  />
                </div>
                <div className="form-control">
                  <label>Email</label>
                  <Field type="email" name="email" />
                  <ErrorMessage
                    component="span"
                    name="email"
                    className="errorMsg"
                  />
                </div>
                <div className="form-control">
                  <label>Phone</label>
                  <Field type="tel" name="phone" />
                  <ErrorMessage
                    component="span"
                    name="phone"
                    className="errorMsg"
                  />
                </div>
                <button className="button" type="submit">
                  {loading ? "Sending..." : " Save Changes"}
                </button>
              </Form>
            </Formik>
          </div>
        </Modal>
      </div>
    </DashboardLayout>
  );
};

export default AdminAccount;
