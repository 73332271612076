import { Menu, MenuItem, Tooltip } from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { url } from "../Api";
import CreateSalesRecord from "../Components/SalesRecordCom/CreateSalesRecord";
import EditSalesRecord from "../Components/SalesRecordCom/EditSalesRecord";
import { NextInstallment } from "../Components/SalesRecordCom/NextInstallment";
import PageToper from "../Components/PageToper";
import { PageContext } from "../Context/PageContextProvider";
import DashboardLayout from "../Layout/DashboardLayout";
import { btnStyle } from "./AllRealtors";
import { SalesRecordDetails } from "../Components/SalesRecordCom/SalesRecordDetails";
import { formatCurrency } from "../utils/formatCurrency";
import { useTable } from "react-table";

const SalesRecord = () => {
  const { userInfo } = useContext(PageContext);
  const [addModal, setAddModal] = useState(false);
  const [sales, setSales] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [getId, setGetId] = useState("");
  const [search, setSearch] = useState("");
  const [filteredSales, setFilteredSales] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openNextI, setOpenNextI] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios({
      url: `${url}/sales`,
      method: "GET",
      headers: {
        authorization: `bearer ${userInfo?.token}`,
      },
    }).then((response) => {
      let data = response.data.sales;
      setSales(data);
      setFilteredSales(data);
      setLoading(false);
    });
  }, [userInfo?.token]);

  useEffect(() => {
    const result = sales.filter((item) => {
      return item.property.name.toLowerCase().match(search.toLocaleLowerCase());
    });
    setFilteredSales(result);
  }, [search]);

  const openEdit = (id) => {
    setEditOpen(true);
    setGetId(id);
  };
  const openSaleDetails = (id) => {
    setOpenModal(true);
    setGetId(id);
  };
  const nextInstallmentClick = (id) => {
    setOpenNextI(true);
    setGetId(id);
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "Property",
        Cell: ({ row }) =>
          row.original.property
            ? row.original.property.name
            : "Property deleted",
      },
      {
        Header: "Realtor",
        accessor: "user.fullname",
      },
      {
        Header: "Payment status",
        accessor: "status",
      },
      {
        Header: "Deposit",
        Cell: ({ row }) => <span>{formatCurrency(row.original.deposit)}</span>,
      },
      {
        Header: "Commission",
        Cell: ({ row }) => (row.original.commissionPaid ? "paid" : "unpaid"),
      },
      {
        Header: "Type",
        Cell: ({ row }) =>
          row.original.property
            ? row.original.property.type
            : "Property deleted",
      },

      {
        Header: "Action",
        Cell: ({ row }) => (
          <>
            <Tooltip
              arrow
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "white",
                  },
                },
              }}
              title={
                <div class="bg-white divide-y divide-gray-100 rounded-lg shadow">
                  <ul class="py-2 text-sm text-gray-700">
                    <li onClick={() => openEdit(row.original._id)}>
                      <span class="block px-4 py-2 hover:bg-gray-100 cursor-pointer">
                        Edit Sale
                      </span>
                    </li>
                    <li>
                      <Link
                        to={`/sales-records/${row.original._id}`}
                        class="block px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      >
                        View Details
                      </Link>
                    </li>

                    {/* <li onClick={() => nextInstallmentClick(row.original._id)}>
                      <span class="block px-4 py-2 hover:bg-gray-100 cursor-pointer">
                        Next Installment
                      </span>
                    </li> */}
                  </ul>
                </div>
              }
            >
              <i className="ri-more-2-fill text-xl cursor-pointer"></i>
            </Tooltip>
          </>
        ),
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: sales,
    });

  // NextInstallment
  return (
    <DashboardLayout>
      <div className="Container">
        <PageToper title="Sales Record" desc="All sales records" adminAccount />
        <div className="flex justify-between">
          <div>
            <input
              placeholder="Search table.."
              className="border border-slate-500 py-2 pl-2 pr-5 font-medium rounded text-sm focus:outline-primary"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <button onClick={() => setAddModal(true)} className="button">
            Add +
          </button>
          <CreateSalesRecord
            open={addModal}
            handleClose={() => setAddModal(false)}
          />
          <EditSalesRecord
            id={getId}
            open={editOpen}
            handleClose={() => setEditOpen(false)}
          />
          <SalesRecordDetails
            id={getId}
            open={openModal}
            handleClose={() => setOpenModal(false)}
          />
          <NextInstallment
            id={getId}
            open={openNextI}
            handleClose={() => setOpenNextI(false)}
          />
        </div>

        {loading && <h5 className="pt-4 font-medium text-lg">Loading....</h5>}

        <div className="w-full overflow-x-auto">
        <table
          {...getTableProps()}
          className="w-full table-auto text-left mt-4"
        >
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()} className="">
                {headerGroup.headers.map((column, i) => (
                  <th
                    key={i}
                    {...column.getHeaderProps()}
                    className="text-xs py-2 text-accent"
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()} className="text-[#2E2E2E]">
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr key={i} {...row.getRowProps()}>
                  {row.cells.map((cell, i) => {
                    return (
                      <td
                        key={i}
                        {...cell.getCellProps()}
                        className="py-2 text-sm px-2"
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        </div>
        {/* <DataTable
          columns={columns}
          data={sales}
          pagination
          fixedHeader
          responsive
          className="overflow-x-auto"
          striped
          highlightOnHover
          subHeader
          subHeaderComponent={
            <input
              placeholder="Search table.."
              className="border border-slate-500 py-2 pl-2 pr-5 font-medium rounded text-sm focus:outline-primary"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          }
          subHeaderAlign="left"
        /> */}
      </div>
    </DashboardLayout>
  );
};

export default SalesRecord;
