import { ErrorMessage, Formik, Form, Field } from "formik";
import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import * as Yup from "yup";
import { toast } from "react-toastify";
import axios from "axios";
import { url } from "../Api/index";
import { PageContext } from "../Context/PageContextProvider";
import authBg from "../Images/authBg.jpg";
import lightBg from "../Images/lightBg.png";
import logo from "../Images/logo.png";

const ForgetPassword = () => {
  const { setOpenBackDrop } = useContext(PageContext);
  const navigate = useNavigate();

  const onSubmit = (values, onSubmitProps) => {
    setOpenBackDrop(true);
    axios({
      url: `${url}/user/forgot/password`,
      method: "post",
      data: {
        email: values.email,
      },
    })
      .then((result) => {
        setOpenBackDrop(false);
        toast.success("Code sent to your email");
        navigate("/reset-password");
        onSubmitProps.resetForm();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setOpenBackDrop(false);
      });
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 h-screen fixed w-full">
      <div
        className="w-full hidden bg-center lg:flex bg-cover bg-no-repeat h-screen"
        style={{ backgroundImage: `url(${authBg})` }}
      ></div>

      <div
        className="Container w-full h-screen bg-center py-10 bg-cover bg-no-repeat text-center relative overflow-x-hidden overflow-y-auto"
        style={{ backgroundImage: `url(${lightBg})` }}
      >
        <div className="formWrap" style={{ maxWidth: 500 }}>
          <div>
            <div
              style={{
                boxShadow:
                  "-2px 2px 5px rgba(0,0,0,0.12), 1px 1px 2px rgba(0,0,0,0.24)",
              }}
              className="pb-5 pt-3 rounded-md px-4 md:px-8"
            >
              <div className="flex justify-center mt-4 mb-6">
                <img src={logo} alt="logo" className="h-[7.2rem]" />
              </div>

              <div className="text-xl mb-3 mt-1 md:text-2xl font-bold inline-block bg-[#E8F0FE] rounded-xl py-[1.8px] px-4">
                Forgot Password
              </div>
              <p className="pt-2 pb-7 text-sm">
                Fill in your email and we will send you a code to reset your
                password.
              </p>

              <Formik
                initialValues={{ email: "" }}
                validationSchema={Yup.object({
                  email: Yup.string()
                    .required("Field is Required!")
                    .email("Invalid email format"),
                })}
                onSubmit={onSubmit}
              >
                <Form>
                  <div className="form-group">
                    <label>Email</label>
                    <Field
                      type="email"
                      name="email"
                      placeholder="Enter email.."
                      className="bg-gray-50"
                    />
                    <ErrorMessage
                      name="email"
                      component="span"
                      className="errorMsg"
                    />
                  </div>
                  <button type="submit" className="button w-full mt-5">
                    Submit
                  </button>
                </Form>
              </Formik>
              <div className="flex justify-end pt-1">
                <div>
                  <Link
                    to="/login"
                    className="cursor-pointer hover:text-secondary text-sm mt-2"
                  >
                    Back to Login
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
