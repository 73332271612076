import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { url } from "../Api";
import CreateRealtor from "../Components/CreateRealtor";
import PageToper from "../Components/PageToper";
import { PageContext } from "../Context/PageContextProvider";
import DashboardLayout from "../Layout/DashboardLayout";
import RealtorDetails from "../Components/RealtorsDetails";
import { Dialog, Slide } from "@mui/material";
import UpLineDetails from "../Components/UpLineDetails";
import { DownLineDetails } from "../Components/DownLineDetails";
import { useTable } from "react-table";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const btnStyle =
  "border border-slate-400 hover:text-secondary hover:border-slate-800 rounded bg-transparent  transition ease-in-out duration-500 text-sm tracking-wider";

const AllRealtors = () => {
  const [addModal, setAddModal] = useState(false);
  const [realtors, setRealtors] = useState([]);
  const { userInfo } = useContext(PageContext);
  const [loading, setLoading] = useState(false);
  const [openFullDialog, setOpenFullDialog] = useState(false);
  const [openUpLineDialog, setOpenUpLineDialog] = useState(false);
  const [openDownLineDialog, setOpenDownLineDialog] = useState(false);
  const [getId, setGetId] = useState("");
  const [search, setSearch] = useState("");
  const [filteredRealtors, setFilteredRealtors] = useState([]);

  const fetchAllRealtors = () => {
    setLoading(true);
    axios({
      url: `${url}/admin/realtors`,
      method: "GET",
      headers: {
        authorization: `bearer ${userInfo?.token}`,
      },
    }).then((response) => {
      setLoading(false);
      let data = response.data.realtors;
      setRealtors(data);
      setFilteredRealtors(data);
    });
  };

  useEffect(() => {
    fetchAllRealtors();
  }, [userInfo?.token]);

  useEffect(() => {
    const result = realtors.filter((item) => {
      return item.fullname.toLowerCase().match(search.toLocaleLowerCase());
    });
    setFilteredRealtors(result);
  }, [search]);

  const openDetails = (id) => {
    setGetId(id);
    setOpenFullDialog(true);
  };

  const openUpLineDetails = (id) => {
    setGetId(id);
    setOpenUpLineDialog(true);
  };

  const openDownLineDetails = (id) => {
    setGetId(id);
    setOpenDownLineDialog(true);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Full Name",
        accessor: "fullname",
      },
      {
        Header: "Email Address",
        accessor: "email",
      },
      {
        Header: "Phone Number",
        accessor: "phone",
      },
      {
        Header: "Upline",
        Cell: ({ row }) => (
          <button
            onClick={() => openUpLineDetails(row.original._id)}
            style={{ padding: "6px 13px" }}
            className={`${btnStyle} text-primary`}
          >
            Upline
          </button>
        ),
      },
      {
        Header: "Downline",
        Cell: ({ row }) => (
          <button
            onClick={() => openDownLineDetails(row.original._id)}
            style={{ padding: "6px 13px" }}
            className={`${btnStyle} text-accent`}
          >
            Downline
          </button>
        ),
      },
      {
        Header: "Action",
        Cell: ({ row }) => (
          <button
            onClick={() => openDetails(row.original._id)}
            style={{ padding: "6px 13px" }}
            className={`${btnStyle} text-primary`}
          >
            Profile
          </button>
        ),
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: filteredRealtors,
    });

  return (
    <>
      {/* Realtors details */}
      <Dialog fullScreen open={openFullDialog} TransitionComponent={Transition}>
        <DashboardLayout>
          <RealtorDetails id={getId} close={() => setOpenFullDialog(false)} />
        </DashboardLayout>
      </Dialog>

      {/* Realtors Upline details */}
      <Dialog
        fullScreen
        open={openUpLineDialog}
        TransitionComponent={Transition}
      >
        <DashboardLayout>
          <UpLineDetails id={getId} close={() => setOpenUpLineDialog(false)} />
        </DashboardLayout>
      </Dialog>

      {/* Realtors Downline details */}
      <Dialog
        fullScreen
        open={openDownLineDialog}
        TransitionComponent={Transition}
      >
        <DashboardLayout>
          <DownLineDetails
            id={getId}
            close={() => setOpenDownLineDialog(false)}
          />
        </DashboardLayout>
      </Dialog>

      <DashboardLayout>
        <div className="Container">
          <PageToper
            title="All Realtors"
            desc="List of all realtors"
            adminAccount
          />

          <div>
            <div className="flex justify-between items-center mb-5">
              <div>
                <input
                  placeholder="Search table.."
                  className="border border-slate-500 py-2 pl-2 pr-5 font-medium rounded text-sm focus:outline-primary"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              <button onClick={() => setAddModal(true)} className="button">
                Add +
              </button>
              <CreateRealtor
                open={addModal}
                handleClose={() => setAddModal(false)}
              />
            </div>

            {!loading && realtors.length === 0 ? (
              <div>
                <h5 className="pt-4 font-medium text-lg">No Realtor yet</h5>
              </div>
            ) : (
              <table
                {...getTableProps()}
                className="w-full table-auto text-left"
              >
                <thead>
                  {headerGroups.map((headerGroup, i) => (
                    <tr
                      key={i}
                      {...headerGroup.getHeaderGroupProps()}
                      className=""
                    >
                      {headerGroup.headers.map((column, i) => (
                        <th
                          key={i}
                          {...column.getHeaderProps()}
                          className="text-xs py-2 text-accent"
                        >
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>

                <tbody {...getTableBodyProps()} className="text-[#2E2E2E]">
                  {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr key={i} {...row.getRowProps()}>
                        {row.cells.map((cell, i) => {
                          return (
                            <td
                              key={i}
                              {...cell.getCellProps()}
                              className="py-2 text-sm px-2"
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}

            {loading && (
              <div>
                <h5 className="pt-4 font-medium text-lg">Loading....</h5>
              </div>
            )}
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};

export default AllRealtors;
