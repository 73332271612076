import React, { useContext, useState } from "react";
import authBg from "../Images/authBg.jpg";
import lightBg from "../Images/lightBg.png";
import logo from "../Images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PageContext } from "../Context/PageContextProvider";
import { url } from "../Api/index";
import * as Yup from "yup";
import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { setOpenBackDrop, setCheckLogin } = useContext(PageContext);
  const navigate = useNavigate();
  // handle toggle
  const toggle = () => {
    setShowPassword(!showPassword);
  };

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .required("Field is Required!")
      .email("Invalid email format"),
    password: Yup.string().required("Field is Required!"),
  });

  const onSubmit = (values, onSubmitProps) => {
    setOpenBackDrop(true);
    axios({
      url: `${url}/user/login`,
      method: "post",
      data: {
        email: values.email,
        password: values.password,
      },
    })
      .then((result) => {
        setOpenBackDrop(false);
        toast.success(result.data.message);
        localStorage.setItem("user_info", JSON.stringify(result.data));
        // setCheckLogin(true);
        navigate("/");
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setOpenBackDrop(false);
      });

    onSubmitProps.resetForm();
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 h-screen fixed w-full">
      <div
        className="w-full hidden lg:flex bg-cover bg-center bg-no-repeat h-screen"
        style={{ backgroundImage: `url(${authBg})` }}
      ></div>

      <div
        className="Container w-full h-screen py-10 bg-cover bg-no-repeat text-center relative overflow-x-hidden overflow-y-auto"
        style={{ backgroundImage: `url(${lightBg})` }}
      >
        <div className="formWrap" style={{ maxWidth: 500 }}>
          <div>
            <div
              style={{
                boxShadow:
                  "-2px 2px 5px rgba(0,0,0,0.12), 1px 1px 2px rgba(0,0,0,0.24)",
              }}
              className="pb-5 pt-3 rounded-md px-4 md:px-8"
            >
              <div className="flex justify-center mt-4">
                <img src={logo} alt="logo" className="h-[7.2rem]" />
              </div>
              <div className="bg-[#E8F0FE] rounded-xl text-xl mb-1 mt-5 md:text-2xl font-bold inline-block py-[1.8px] px-4">
                Welcome Back
              </div>
              <p className="pt-2 pb-7">Login to your account</p>

              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                validateOnMount
              >
                <Form>
                  <div className="form-group">
                    <label>Email Address</label>
                    <Field
                      type="email"
                      name="email"
                      placeholder="johndoe@gmail"
                    />
                    <ErrorMessage
                      name="email"
                      component="span"
                      className="errorMsg"
                    />
                  </div>
                  <div className="form-group mt-4 my-5">
                    <label>Password</label>
                    <div className="flex border rounded-md items-center">
                      <Field
                        type={showPassword === false ? "password" : "text"}
                        placeholder="Enter your password"
                        className="border-none"
                        name="password"
                      />
                      {showPassword ? (
                        <i
                          className="ri-eye-off-fill pr-3 text-xl cursor-pointer"
                          onClick={toggle}
                        ></i>
                      ) : (
                        <i
                          className="ri-eye-fill pr-3 text-xl cursor-pointer"
                          onClick={toggle}
                        ></i>
                      )}
                    </div>
                    <ErrorMessage
                      name="password"
                      component="span"
                      className="errorMsg"
                    />
                  </div>
                  <button type="submit" className="button w-full">
                    Login
                  </button>
                </Form>
              </Formik>
              <div className="flex justify-end pt-1">
                <div>
                  <Link
                    to="/forget-password"
                    className="cursor-pointer hover:text-secondary text-sm mt-2"
                  >
                    Forget password
                  </Link>
                </div>
              </div>

              <div className="text-sm mt-6 flex items-center gap-x-2 justify-center">
                <p> Don't have an account yet ? </p>
                <Link
                  to="/register"
                  href="#!"
                  className="text-primary hover:text-secondary"
                >
                  Register Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login